/* You can add global styles to this file, and also import other style files */
//@import "./styles/settings/_variables.scss";
@import "./styles/settings/_variables.scss";
@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-quartz.css";

/** theme colors **/
body {
  .theme-base-dark {
    background: var(--base-dark);
  }

  .color-theme-base-dark {
    color: var(--base-dark);
  }

  .theme-base-medium-dark {
    background: var(--base-medium-dark);
  }

  .color-theme-base-medium-dark {
    color: var(--base-medium-dark);
  }

  .theme-base-medium {
    background: var(--base-medium);
  }

  .color-theme-base-medium {
    color: var(--base-medium);
  }

  .theme-base-medium-light {
    background: var(--base-medium-light);
  }

  .color-theme-base-medium-light {
    color: var(--base-medium-light);
  }

  .theme-base-light {
    background: var(--base-light);
  }

  .theme-base-status-processing {
    background-color: var(--base-status-processing);
  }

  .color-theme-base-status-processing {
    color: var(--base-status-processing);
  }

  .theme-base-status-error {
    background-color: var(--base-status-error);
  }

  .color-theme-base-status-error {
    color: var(--base-status-error);
  }

  .theme-base-status-info {
    background-color: var(--base-status-info);
  }

  .color-theme-base-status-info {
    color: var(--base-status-info);
  }

  .theme-base-modal-medium-light {
    background-color: var(--base-modal-medium-light);
  }

  .color-theme-base-modal-medium-light {
    color: var(--base-modal-medium-light);
  }

  .theme-base-status-error {
    background-color: var(--base-status-error);
  }

  .color-theme-base-status-error {
    color: var(--base-status-error);
  }

  .theme-base-warning {
    background-color: var(--base-warning);
  }

  .color-theme-base-warning {
    color: var(--base-warning);
  }

  .color-theme-base-moodal-dark {
    color: var(--base-modal-dark);
  }

  .color-theme-base-medium-alt {
    color: var(--base-medium-alt);
  }

  .color-theme-base-medium-light-alt {
    color: var(--base-medium-light-alt);
  }

  .theme-action-button {
    @extend .theme-base-light;
    border-radius: 0;
    border: 1px solid var(--base-dark);
    color: var(--base-dark);
    background-color: var(--base-light);
    margin: 0 auto;
  }

  .theme-action-icon-button {
    @extend .theme-action-button;
    padding: 0 7px;
    border-radius: 0;
  }

  .action-msg-small {
    font-size: 12px;
  }

  .action-msg-medium {
    font-size: 14px;
  }

  .overflow-auto {
    overflow: auto;
  }

  .loading-issue {
    mdb-card-img {
      background-color: var(--base-dark);
      text-align: center;

      img {
        width: 50%;
        margin: 0 auto;
      }
    }

    a {
      color: var(--base-medium) !important;

      &:hover,
      &:active {
        color: var(--base-medium-light) !important;
      }
    }
  }

  .animated-ellipsis::after {
    content: "";
    animation: ellipsis 1.5s infinite;
  }
}

/** global styles **/
body {
  font-family: "Noto Sans", sans-serif;
  color: #000;

  p,
  span,
  div,
  td,
  th {
    font-family: "Noto Sans", sans-serif;
  }

  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  p {
    color: #000;
  }

  .strong,
  .bold,
  b,
  strong {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .semi-bold {
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
  }

  .italic {
    font-style: italic;
  }

  .h2,
  h2 {
    font-size: 1.44rem;
    font-weight: 600;
    margin-top: 0.2rem;
  }

  .h4,
  h4 {
    font-size: 1.42rem;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-13 {
    font-size: 13px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-15 {
    font-size: 15px;
  }

  .font-16 {
    font-size: 16px;
  }

  .error {
    color: var(--base-error);
  }

  .warning {
    color: var(--base-warning);
  }

  // .page {
  //   padding: 0 14px 84px;
  // }
  .cursor-pointer {
    cursor: pointer;
  }

  .header-row {
    margin-top: var(--header-margin-top);
    margin-bottom: 42px;
  }

  .copy-callout {
    background: var(--base-light);
    padding: 0.8rem;
  }

  .button-overlay {
    .p-overlaypanel-content {
      padding: 0;
    }

    .copy-to {
      padding: 0.5rem;
    }
  }

  ul {
    &.list-no-style {
      list-style-type: none;
      padding: 0;
    }

    &.overlay-list {
      list-style: none;
      padding: 0;
      margin: 0;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      li {
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .title-callout {
    display: flex;
    flex-direction: row;

    .callout-headers-wrapper {
      .callout-headers {
        padding: 0.8rem;
        margin-right: 0.8rem;
        background: white;
        border-bottom: 1px solid var(--base-medium-dark);
        border-right: 1px solid var(--base-medium-dark);
        color: var(--base-dark);
        -webkit-box-shadow: 2px 2px 3px -1px var(--base-box-shadow);
        box-shadow: 2px 2px 3px -1px var(--base-box-shadow);
      }
    }

    .callout-text-wrapper {
      padding: 0.8rem;
      background: var(--base-light);
      border-bottom: 1px solid var(--base-medium-dark);
      border-left: 1px solid var(--base-medium-dark);
      -webkit-box-shadow: -2px 2px 3px -1px var(--base-box-shadow);
      box-shadow: -2px 2px 3px -1px var(--base-box-shadow);
      flex: 1;

      p {
        margin-bottom: 0.6rem;

        &:only-child,
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .sub-title-callout {
    padding: 0.5rem;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid var(--base-medium-dark);
    border-right: 1px solid var(--base-medium-dark);
    color: var(--base-dark);
    -webkit-box-shadow: 2px 2px 3px -1px var(--base-box-shadow);
    box-shadow: 2px 2px 3px -1px var(--base-box-shadow);

    h3 {
      margin-bottom: 0;
    }
  }

  .style-table {
    display: table;
    width: 100%;
  }

  .style-table-row {
    display: table-row;
  }

  .style-table-cell {
    display: table-cell;
    padding: 10px;
  }

  .data-card {
    h3 {
      background: var(--base-medium-dark);
      padding: 0.8rem;
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--base-light);
    }
  }

  .static-error {
    bottom: -23px;
    top: auto;

    &.error-hiding {
      opacity: 0;

      &.fade-in {
        animation: fadeIn 1s ease-in-out forwards;
      }
    }
  }

  .card-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

    .display-card {
      .card-banner {
        padding-top: 3.2rem;
        width: 100%;
        background-color: var(--base-medium-dark);
      }

      flex: 1 0 16rem;
      max-width: 16rem;
      margin: 0 2rem 2rem;
      min-height: 16rem;

      &:first-child {
        margin-left: 0;
      }
    }

    .card {
      min-height: 16rem;
      margin: 0 15px 30px 15px;
    }
  }

  .ellipsis-wrapper {
    display: flex;
    align-items: center;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .collapsing {
    overflow: hidden !important;
  }

  .mdb-select-dropdown {
    border-radius: 8px;

    &.access-dropdown {
      min-width: 204px;
    }

    .mdb-select-options-wrapper {
      .mdb-option {
        font-size: 14px;
      }
    }
  }

  &.all-media,
  &.meetings,
  &.skills {
    .cdk-overlay-pane {
      max-width: 120%;

      .mdb-select-dropdown {
        width: 120%;
        min-width: 120%;
      }
    }
  }

  /*.note {
    font-size: 0.9rem;
    color: var(--base-medium-dark);
  }*/
  .note {
    font-family: "Noto Sans", sans-serif;
    color: #000;

    .row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    p {
      color: #000;
    }

    b,
    strong {
      font-weight: bold;
    }
  }

  .btn-group {
    button {
      background-color: var(--base-medium-dark);
      color: var(--base-light);

      &.secondary {
        background-color: var(--base-light);
        color: var(--base-dark);
      }
    }

    &.btn-icons {
      .btn-icon {
        padding: 1rem 0.4rem;
        font-size: 1rem;
      }
    }
  }

  .btn-outline-theme {
    border: 2px solid var(--base-medium-dark);
    border-radius: 0;
    // margin: 0 0.375rem 2rem;
  }

  .btn-outline-theme-medium {
    border: 2px solid var(--base-medium);
    border-radius: 0;
    // margin: 0 0.375rem 2rem;
  }

  .btn-outline-theme-light {
    border: 2px solid var(--base-medium-light);
    border-radius: 0;
    // margin: 0 0.375rem 2rem;
  }

  .btn {

    &.disabled,
    :disabled {
      opacity: 0.42;
    }

    &.looks-disabled,
    &.looks-disabled:not(:disabled):not(.disabled) {
      opacity: 0.42;
      cursor: default;

      &:hover {
        background-color: var(--base-dark);
        box-shadow: none;
      }
    }

    &.btn-link {
      padding: 0.21rem 0.535rem;
    }

    &.btn-clear {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      font-size: inherit;
      line-height: 1.5;
      vertical-align: baseline;
      text-transform: none;
      text-transform: none;
      box-shadow: none;
      cursor: default;

      &:hover {
        color: inherit;
      }

      &:not(:disabled):not(.disabled) {
        cursor: default;
      }

      &:not([disabled]):not(.disabled):active {
        box-shadow: none;
      }
    }

    &.inline {
      padding: 0;
      text-transform: none;
      margin: 0;
      font-size: inherit;
      line-height: 1.5;
      vertical-align: baseline;
    }

    // &:hover {
    // background-color: var(--base-medium);
    // color: #ffffff;
    // }
    &.theme-modal-button {
      border-radius: 8px;
      border: 1px solid var(--base-dark);
      color: var(--base-light);
      background-color: var(--base-dark);
      margin: 0;
      padding: 0px 16px;
      font-size: 14px;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--base-dark);
        opacity: 0.62;
      }

      &.light {
        background-color: var(--base-light);
        color: var(--base-dark);
      }

      &.medium {
        background-color: var(--base-medium);
        color: var(--base-dark);
      }

      &.danger {
        background-color: var(--base-error);
        color: var(--base-light);
      }

      .action-icon {
        margin-right: 7px;
        font-size: 16px;
      }

      fa-icon {
        margin-right: 9px;
      }
    }

    &.theme-modal-cancel-button {
      color: var(--base-dark);
      font-size: 14px;
      text-decoration: underline;
      text-underline-offset: 3px;
      margin: 0 0 0 24px;
    }
  }

  a {
    color: var(--base-medium-light);

    &:hover {
      color: var(--base-dark);
    }
  }

  .loading-card {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {
      width: 4rem;
      height: 4rem;
    }
  }

  mdb-popover-container {
    border: 1px solid transparent;

    .popover-body {
      padding: 0;
      box-shadow: none;

      .custom-pop {
        width: 118px;
        text-align: center;
        line-height: 1.3;
        background: var(--base-medium);
        padding: 6px 6px;
        box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
        border-radius: 8px;

        .left-arrow {
          position: absolute;
          display: none;
          right: calc(-0.5rem - 1px);
          width: 0.5rem;
          height: 1rem;
          margin: 0.3rem 0;

          &:before,
          &:after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
          }

          &:after {
            right: 1px;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-left-color: var(--base-medium);
          }

          &:before {
            right: 0;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-left-color: rgba(0, 0, 0, 0.25);
          }
        }

        p {
          margin: 0;
          color: #fff;
        }

        &.error-pop {
          background: var(--base-error);

          .left-arrow {
            &:after {
              border-left-color: var(--base-error);
            }
          }
        }

        &.wider {
          width: 350px;

          &.near-right {
            transform: translate3d(-75px, 0, 0px);
          }
        }

        &:after {
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;
          content: "";
          border-width: 10px;
        }

        &:before {
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid;
        }

        .theme-modal-button {
          font-size: 12px;
        }

        .theme-modal-cancel-button {
          color: #fff;
          font-size: 12px;
          margin-left: 6px;
          padding: 0;
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px 0;
      }
    }

    &.popover-top {

      &:before,
      &:after {
        display: none;
      }

      .custom-pop {
        &:after {
          left: 50%;
          border-bottom-width: 0;
          bottom: -10px;
          margin-left: -10px;
          border-top-color: var(--base-medium);
        }

        &.wider {
          &.near-right {
            &:after {
              margin-left: 3px;
              left: auto;
              right: 52px;
            }
          }
        }

        &.error-pop {
          &:after {
            border-top-color: var(--base-error);
          }
        }
      }
    }

    &.popover-left {

      &:before,
      &:after {
        display: none;
      }

      .custom-pop {

        .left-arrow {
          display: block;
        }
      }

    }
  }

  #uploadCSV {
    position: absolute;
    right: 12px;
    top: 7px;

    .file-path-wrapper {
      display: none;
    }
  }

  .admin-tab-styles {
    .nav {
      padding: 0.3em 0.4em 0 1em;

      .nav-item {
        margin-right: 1rem;

        &.active {
          border-bottom: 5px solid var(--base-dark);
        }

        a {
          padding: 0.5rem 0 0;
        }
      }
    }
  }

  app-ag-renderer-score-bar {
    width: 100%;
  }

  .score-bar-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .progress {
      width: 100%;
    }

    .progress-bar {
      background-color: var(--base-status-info);
    }

    &.score-type-medium {
      .progress-bar {
        background-color: var(--base-status-warning);
      }
    }

    &.score-type-low {
      .progress-bar {
        background-color: var(--base-status-error);
      }
    }
  }

  .overlay-container {
    #toast-container {
      &.toast-top-center {
        top: 0;
        right: calc(50% - 185px);
        width: 390px;
      }

      >app-custom-toast,
      >mdb-toast-component {
        border: 2px solid var(--base-medium-dark-alt);
        border-radius: 8px;
        color: var(--base-medium-dark-alt);
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background-size: 0 0;

        .toast-message {
          display: flex;
          align-items: center;
          justify-content: center;

          fa-icon {
            margin-right: 7px;
          }
        }

        fa-icon {
          display: none;
          font-size: 21px;
        }

        .toast-close-button {
          color: var(--base-medium-dark-alt);
          right: 5px;
          position: absolute;
          top: -2px;

          fa-icon {
            display: block;
          }
        }

        &.success {
          .success-icon {
            display: block;
          }
        }

        &.error {
          .error-icon {
            display: block;
          }
        }

        &.saving {
          .saving-icon {
            display: block;
          }
        }

        &.warning {
          .warning-icon {
            display: block;

            .info-button {
              background-color: var(--base-light);
              padding: 0;
              margin: 0;
              width: 23px;
              height: 23px;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 52px;
              top: -12px;

              .fas {
                font-size: 1.5rem;
                color: var(--base-medium-dark);
              }
            }
          }
        }
      }
    }
  }

  form {
    .md-form {

      input {
        &.disabled {
          opacity: 0.42;
        }
      }

      &.search {
        position: relative;

        fa-icon {
          position: absolute;
          top: 14px;
          right: 2px;
          font-size: 17px;
          color: var(--base-modal-dark);
        }
      }

      &.static-error {
        .error-message {
          position: relative;
          top: 5px;
        }
      }

      .info-button {
        background-color: var(--base-light);
        padding: 0;
        margin: 0;
        width: 23px;
        height: 23px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 52px;
        top: -12px;

        .fas {
          font-size: 1.5rem;
          color: var(--base-medium-dark);
        }
      }
    }

    .grid {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 4px;
      width: 100%;

      .grid-item {
        &:nth-child(even) {
          margin-left: 7px;
        }

        &.checkbox-wrapper {
          .md-form {
            margin-top: 0;
            transform: translateY(-12px);
          }
        }
      }
    }
  }

  .md-form {

    mdb-select {
      .dropdown-content {
        li {
          &.disabled {
            opacity: 0.62;
          }
        }
      }
    }

    mdb-select-2 {
      &.custom-arrow {
        .mdb-select-arrow {
          right: 8px;
          top: 19px;
          transform: rotate(180deg);

          &::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 10px 7.5px;
            border-color: transparent transparent #002738 transparent;
          }
        }
      }
    }
  }

  app-text-inline-edit {

    .text-inline-wrapper {
      display: flex;
      align-items: center;

      .md-form {
        margin: 0;
        height: 30px;
        align-items: center;

        .label,
        label {
          display: none;
        }

        input {
          margin: 0 0 0 0.5rem;
          background: transparent;
          padding: 0 !important;
          height: 30px;
        }
      }

      mdb-select-2 {
        min-width: 170px;

        .mdb-select-wrapper {
          height: 30px;

          .mdb-select-value {
            padding: 0;
            margin: 0.12rem 0 0;
          }
        }
      }

      button.btn {
        box-shadow: none;
        background-color: transparent;
        margin: 0;
        padding: 0 0 0 0.8rem;

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active {
          box-shadow: none;
        }

        fa-icon {
          color: var(--base-dark);
          font-size: 16px;
        }
      }

      .cell {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        input {
          height: auto;
          padding: 0.11rem 0 0 0.5rem;
          background: transparent;
          border: none;
          flex-grow: 1;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }
  }

  .left-side-nav {
    h2 {
      margin: 0;
    }

    .theme-modal-button {
      margin-left: 15px;
    }
  }

  .right-side-nav {
    .navbar-text {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .dropdown-menu {
    p.disabled-link {
      font-size: 0.9rem;
      margin-left: 0;
      padding: 0.5rem;
      opacity: 0.5;
      margin-bottom: 0;
    }
  }

  .transparent {
    opacity: 0;

    td {
      border-top: none;
    }
  }

  .button-column {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .btn {
      margin-bottom: 0;
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      &:after {
        margin-left: 0.555rem;
      }
    }

    .show>.dropdown-menu {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      button {
        white-space: nowrap;
        margin-bottom: 0.8rem;
      }

      &.right-aligned {
        left: auto;
        right: 0.6rem;
        align-items: flex-end;
      }
    }
  }

  .empty-message {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 8%;
  }

  mdb-accordion {
    &.custom-icons {
      &.icons-plus-minus {
        .mdb-accordion-indicator {
          &::after {
            font-family: "Font Awesome 5 Free";
            content: "\f068";
            font-weight: 900;
            transform: unset;
            border: 0;
          }
        }

        .is-collapsed {
          .mdb-accordion-indicator {
            &::after {
              content: "\2b";
            }
          }
        }
      }
    }
  }

  &.all-media {
    .cdk-overlay-container {
      .mydp {
        left: -8vw;
      }
    }
  }

  &.modal-open {
    overflow: hidden !important;

    //for media management page - to hide model-viewer holder when generating 3d object thumbnails
    .tab-pane {
      position: relative;
      width: 100%;

      #modelViewerHolder {
        padding-top: 50px;
      }

      #mediaManagement {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;
        background: white;
      }
    }
  }

  .modal {
    overflow-x: auto;

    .modal-fluid {
      &.media-modal-dialog {
        max-width: 92%;
      }
    }

    .modal-full-height.modal-right {
      width: 60%;
      min-width: 1026px;
      border-radius: 8px;

      &.media-display {
        min-width: 1080px;
        max-width: 1200px;
      }

      &.grid-display {
        min-width: 1080px;
        max-width: none;
        width: 100%;
        padding-left: 247px;
      }

      .modal-content {
        height: 100vh;
      }

      &.modal-full-width {
        width: 100%;
        max-width: none;
        min-width: 100%;
      }

    }

    &.overlay {
      overflow-y: hidden;
    }

    .modal-dialog {
      overflow: hidden;
      height: 100vh;

      .modal-header {
        padding: 0.72rem 0.6rem 0.7rem;
        border-bottom: 1px solid var(--base-dark);
        background: var(--base-modal-medium);

        .modal-title {
          font-size: 23px;
          font-weight: 600;
        }
      }

      .modal-footer {
        padding: 11px 27px;
        border-top: 1px solid var(--base-dark);
        background: var(--base-modal-medium);

        &.with-preview {
          display: flex;
          justify-content: space-between;

          .preview {
            background: var(--base-modal-medium-light);
            border-radius: 8px;
            padding: 9px 15px;
            display: flex;

            .content {
              margin-right: 1rem;
            }

            .thumbnail-container {
              height: auto;
              width: 100%;

              .img-thumbnail {
                min-width: 100px;
                height: 70px;
                overflow: hidden;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                cursor: pointer;
                border-radius: 8px;
                position: relative;
              }
            }
          }

          .modal-actions {
            display: flex;
          }
        }

      }

      .striped-form {
        .row {
          &.striped {
            background: var(--base-modal-light);
          }

          .grid {
            .grid-item {
              padding: 14px 9px 9px;

              .md-form {
                padding: 0.8rem 0 0.4rem 0;
                margin-bottom: 0;

                label {
                  top: 0;
                  color: var(--base-status-processing);
                }

                mdb-error {
                  top: auto;
                  bottom: -5px;
                  font-size: 12px;
                }
              }

              .dates {
                margin-top: 0;
                align-items: flex-start;
                position: relative;

                .md-form {
                  mdb-date-picker {
                    input {
                      font-size: 1rem;
                      margin-bottom: 0px;
                      padding: 1px 0 7px;
                      line-height: 1.7;

                      &#asOfDate {
                        margin-right: 12px;
                      }
                    }
                  }

                  mdb-select-2 {
                    .mdb-select-value {
                      padding: 16px 0 8px;
                    }
                  }
                }
              }

              .search {
                .mdb-autocomplete-clear {
                  right: 22px;
                  top: 13px;
                  font-size: 1.1rem;
                }
              }
            }
          }
        }
      }

      .modal-content-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;

        .media-modal {
          .upload-embed {
            display: flex;
            justify-content: flex-end;
          }

          app-media-manager-table-view {
            overflow: auto;
          }
        }

        .close {
          right: 8px;
          top: 6px;
        }

        .modal-fluid-content {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          margin: 16px 0;

          .row {
            &.info-row {
              .info-padded {
                font-size: 14px;
                padding: 12px 12px 10px;
                line-height: 1.7;
                letter-spacing: 0.5px;
                background: var(--base-modal-medium-light);
                position: relative;
              }

              .search-or-create {
                display: flex;
                flex-direction: column;
                margin-top: 25px;
                margin-bottom: 6px;

                form {
                  display: flex;

                  .md-form {
                    margin: 0;
                    flex: 1;

                    input {
                      line-height: 1.7;
                      padding: 0.3rem 0 0.05rem 0;
                      font-size: 14px;
                    }

                    label {
                      font-size: 16px;
                    }
                  }
                }

                fa-icon {
                  top: 0px;
                }

                mdb-error {
                  top: 30px;
                  left: auto;
                  right: 0;
                }

                .action-button {
                  display: flex;
                  align-items: flex-end;
                  padding: 0px 17px;

                  .btn {
                    margin-bottom: 9px;
                  }
                }

                .mdb-autocomplete-clear {
                  right: 20px;
                  top: 1px;
                }

                .new-group {
                  flex: 1;

                  .group-name {
                    margin-bottom: 21px;
                  }
                }
              }
            }
          }

          p {
            margin-bottom: 1.2rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            &.experience-buttons {
              button {
                margin-right: 2.7rem;
              }
            }
          }

          div[class^="col-"] {
            padding: 0;
          }

          .details-row {
            margin-bottom: 24px;
            border-bottom: 3px solid var(--base-modal-dark);
            padding-bottom: 26px;
          }

          .form-radio-buttons {
            display: flex;
            flex-direction: column;
            height: 100%;

            .access-row {
              background-color: var(--base-modal-medium-light);
              padding: 9px 9px;

              &.top {
                margin-bottom: 3px;
              }

              &.bottom {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: center;
              }

              .access-type {
                margin-bottom: 10px;
              }

              .list-start {
                margin: 0 15px;
              }
            }

            &.group-type {
              display: flex;
              height: 100%;
              margin-top: 2px;
              flex-direction: row;
              align-items: center;
            }
          }

          .event-properties-row {
            margin-bottom: 11px;

            .grid {
              .grid-item {
                min-height: 66px;

                .md-form {
                  margin-bottom: 0;
                }

                .prop-wrapper {
                  background: var(--base-modal-medium-light);
                  margin-right: 8px;
                  height: 46px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;

                  button {
                    margin: 0 14px 0 9px;
                  }

                  .thumbnail-container {
                    background-color: var(--base-medium-dark-alt);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                    background-size: cover;
                  }

                  fa-icon {
                    color: white;
                    font-size: 17px;
                  }
                }
              }
            }
          }

          .container-overflow-auto {
            height: 100%;
            overflow: auto;
            padding: 0 21px;
          }

          .form-split {
            >div[class^="col-"]:first-child {
              max-width: 49%;
              margin-right: 1%;
            }

            >div[class^="col-"]:last-child {
              max-width: 49%;
              margin-left: 1%;
            }
          }

          form {
            flex: 1;

            .form-check-inline {
              margin-right: 1.05rem;
              border-radius: 8px;
              padding: 1px 5px 1px 1px;
              border: 1px solid var(--base-modal-medium-dark);

              &.selected {
                background: var(--base-medium);
              }

              &.disabled {
                opacity: 0.42;
              }
            }

            .md-form {
              margin-top: 5px;

              label {
                top: -4px;
              }

              // &.ap-autocomplete {
              //   input {
              //     padding: 1.1rem 0 0.4rem 0;
              //   }
              // }

              .form-control {
                line-height: 1.7;
                border-color: var(--base-modal-dark);
              }

              .form-check-input[type="checkbox"]+label:before {
                border-color: var(--base-modal-dark);
              }

              .form-check-input[type="checkbox"]:checked+label:before {
                border-color: transparent var(--base-medium-dark) var(--base-dark) transparent;
              }

              mdb-select-2 {
                .mdb-select-clear-btn {
                  font-weight: bold;
                }
              }

              &.skill-pdf {
                background: var(--base-modal-medium-light);
                margin-right: 8px;
                height: 46px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                button {
                  margin: 0 14px 0 9px;
                }
              }
            }

            &.modal-controlled {
              .md-form {
                margin-top: 0;

                .form-control {
                  line-height: 1.5;
                }
              }
            }

            .event-name {
              padding-top: 15px;

              .md-form {
                label {
                  top: -12px;
                }

                .error-message {
                  top: 44px;
                }
              }
            }

            .dates {
              margin-top: 50px;
              display: flex;
              align-items: baseline;

              .datepicker-icon {
                display: none;
              }

              .text {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
              }

              .md-form {
                margin-bottom: 0;
                margin-top: 0;

                input {
                  margin: 0;
                  line-height: 1.5;
                }

                mdb-date-picker {
                  input {
                    margin-bottom: -2px;

                    &#startDate {
                      margin-right: 12px;
                    }

                    &#endDate {
                      margin-left: 12px;
                    }
                  }
                }

                mdb-select-2 {
                  .mdb-select-icons-wrapper {
                    display: none;
                  }
                }
              }
            }
          }

          .fluid-content {
            flex: 1;
            overflow: hidden;

            .container-fluid {
              min-height: auto;
              height: 100%;
            }

            .tabs-table {
              mdb-tabset {
                height: 100%;
              }

              .container-fluid {
                .row {
                  height: 100%;
                  flex-direction: column;
                  flex-wrap: nowrap;
                }

                .tab-content-fluid {
                  flex: 1;
                  overflow: auto;
                }
              }

              .h-100 {
                mdb-tab {
                  height: 100%;
                  padding-top: 22px;
                }
              }
            }
          }

          .repeater-form {
            margin-top: 12px;

            .repeater:nth-of-type(odd) {
              background: var(--base-modal-light);
            }

            .repeater {
              .title {
                margin-bottom: 8px;
              }

              .repeater-item {
                padding: 0px 9px;
                margin-bottom: 6px;
                display: flex;
                align-items: center;

                .md-form {
                  flex: 1;
                  margin: 27px 1% 17px 0;
                  min-width: 8rem;
                  max-width: 8rem;

                  &.larger {
                    min-width: 10.5rem;
                    max-width: 10.5rem;
                  }

                  &:last-child {
                    margin-right: 0;
                  }

                  label {
                    top: -1px;
                  }

                  mdb-select-2 {
                    &.custom-arrow {
                      .mdb-select-arrow {
                        top: 25px;
                        right: 0;
                      }
                    }

                    label {
                      top: 0;
                    }

                    .mdb-select-wrapper {
                      height: 50px;
                      margin-top: 5px;
                    }
                  }

                  mdb-error {
                    top: auto;
                    bottom: -14px;
                    font-size: 12px;
                  }

                  &.first-name {
                    flex: 0 0 12%;
                  }

                  &.last-name {
                    flex: 0 0 12%;
                  }

                  &.username {
                    flex: 0 0 14%;
                  }

                  &.email {
                    flex: 0 0 22%;
                  }

                  &.system-capability {
                    flex: 0 0 17%;
                  }
                }

                .btn-icon {
                  padding: 0.7rem 0.4rem 1rem 0;
                  min-width: 2rem;

                  &.add {
                    fa-icon {
                      color: var(--base-medium-dark-alt);
                      font-size: 28px;
                    }
                  }

                  &.remove {
                    fa-icon {
                      font-size: 21px;
                      color: var(--base-medium);
                    }
                  }

                  &.minus {
                    margin: -21px 0 0 3px;
                    font-size: 19px;

                    fa-icon {
                      color: var(--base-medium);
                    }
                  }

                  &:disabled {
                    opacity: 0.4;
                  }
                }
              }
            }

            &.with-persona {
              .repeater {
                .repeater-item {
                  .md-form {
                    &.email {
                      flex: 0 0 14%;
                    }

                    &.system-capability {
                      flex: 0 0 12%;
                    }
                  }
                }
              }
            }
          }

          .accordion {
            .card-header {
              padding: 11px 12px;
              background: var(--base-modal-medium);

              h5 {
                font-size: 16px;
                color: var(--base-dark);
              }

              .mdb-accordion-indicator {
                top: 0;
                right: -11px;
              }
            }

            .card.is-collapsed {
              .rotate-icon {
                font-size: 29px;
              }
            }

            mdb-accordion-item-body {
              background: var(--base-modal-light);

              .card-body {
                padding: 27px 20px;
              }
            }

            .experiences-core-properties {
              .md-form {
                margin-bottom: 24px;
              }
            }
          }

          .experiences {
            .grid-item {
              button {
                display: flex;
                align-items: end;
                padding-bottom: 0.65rem;

                &:disabled {
                  &.add-button {
                    opacity: 0;

                    &.show {
                      opacity: 0.65;
                    }
                  }
                }

                fa-icon {
                  font-size: 21px;
                }
              }
            }
          }

          .experiences-hub {
            .zones-fields-wrapper {
              .zone-title {
                background: var(--base-medium-dark);
                padding: 3px 12px;

                p {
                  margin: 0;
                  color: #fff;
                  font-weight: 600;
                }
              }

              .grid {
                padding: 14px 12px 15px;
              }

              .extras-actions {
                padding: 0 0 24px;

                .extra {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .thumbnail-container {
                    width: 230px;
                    height: 115px;
                    margin-left: 54px;
                    background: var(--base-medium-dark);
                    background-position: center center;

                    &.fallback {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      fa-icon {
                        font-size: 58px;
                        color: #fff;
                      }
                    }
                  }
                }
              }

              .md-form {
                margin: 13px 0 0px;

                input {
                  margin-bottom: 9px;
                  height: 24px;
                }

                &.experience {
                  margin-left: 12px;
                }
              }
            }
          }

          &.add-type-invite_users {
            .repeater-form {
              .repeater {
                .repeater-item {
                  .md-form {
                    max-width: none;

                    &.email {
                      flex: 0 0 33%;
                    }

                    &.system-capability {
                      flex: 0 0 33%;
                    }
                  }
                }
              }
            }
          }
        }

        .add-user-management-card {
          padding: 0 21px;
          margin-bottom: 0;

          form>.row {
            .col-4 {
              padding-left: 0;
            }

            .col-8 {
              position: relative;
              padding-right: 0;

              .validation-small-message {
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }

          .user-card {
            box-shadow: none;
            padding-bottom: 23px;
          }

          .available-users {
            .md-form {
              margin: 9px 0 0;

              input {
                padding: 0.8rem 0 0.4rem 0;
              }
            }
          }

          .admin-tab-styles {
            border: 1px solid var(--base-medium);
            border-radius: 8px;
            margin-top: 3px;

            .nav {
              padding: 0.3em 0.4em 0 1em;

              .nav-item {
                margin-right: 1rem;

                &.active {
                  border-bottom: 5px solid var(--base-medium);
                }

                a {
                  padding: 0.5rem 0 0;
                }
              }
            }
          }

          .available-users-table {
            tr {
              td {
                padding: 0rem 0.1rem 0.7rem 0.2rem;
                font-size: 15px;
                border-color: transparent;

                .btn {
                  margin: 0 0 0 14px;
                  padding: 0;

                  fa-icon {
                    font-size: 18px;
                    color: var(--base-medium);
                  }
                }
              }
            }
          }
        }

        div.available.tabs-table {
          margin-right: 6px;
        }

        div.roster.tabs-table {
          display: flex;
          align-items: flex-end;
          padding-bottom: 26px;
          margin-top: 3px;
          max-width: calc(66.66666667% - 6px);

          .action-button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
          }

          .admin-tab-styles {
            margin-left: 3px;
            border: none;
            width: 100%;

            .tab-nav-fluid {
              padding-top: 10px;

              .nav {
                padding-top: 0;
                padding-left: 16px;
                border-bottom: none;

                a {
                  padding-top: 0.2rem;
                }
              }
            }
          }

          .tab-content-fluid {
            border: 1px solid var(--base-medium);
            border-radius: 8px;

            .added-tabs {

              mdb-tab {
                padding-top: 0;
              }

              .responsive {
                >div {
                  border-radius: 8px;

                  thead {
                    background-color: var(--base-modal-medium);

                    tr {
                      th {
                        padding: 18px 1.2% 14px;
                        color: var(--base-dark);
                      }
                    }
                  }

                  tbody {
                    td {
                      padding: 0.5rem 1.2%;

                      .btn {
                        padding: 0;
                        margin: 0;

                        fa-icon {
                          font-size: 16px;
                          margin-right: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {

    &.manage-system-users-container,
    &.group-roster-container,
    &.event-media-container,
    &.event-roster-container {
      .modal-full-height.modal-right {
        min-width: 1200px;
      }
    }
  }


  .modal-backdrop {
    z-index: 1041;
  }

  .modal {
    &.fade {
      .modal-dialog {
        //transform incoming from right side
        transform: translateX(100%);
        transition: transform 600ms ease-out;
      }
    }

    &.show {
      .modal-dialog {
        transform: none;
      }
    }

    &.secondary {
      z-index: 1040;

      .modal-dialog {
        //transform to absolute position left side
        right: 100%;
        transform: translate(100%, 0);
        transition: all 600ms ease;
      }
    }

    &.restore-primary {
      .modal-dialog {
        right: 0;
        transition: all 600ms ease;
      }
    }
  }

  .modal {
    &.media-details-modal {
      .media-preview {
        position: relative;
        height: 50.5vh;
        max-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--base-medium-dark);

        .media-preview-icon {
          color: #fff;
          font-size: 14rem;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &.audio-preview {
            padding-bottom: 55px;
          }
        }

        .loading-screen {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;

          .spinner-border {
            color: #fff;
          }
        }

        img {
          max-height: 100%;
        }

        app-video-player {
          height: 99%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .video-js {
            max-height: 100%;
            height: 100%;
            padding-top: 0;

            .vjs-tech {
              cursor: pointer;
              background: var(--base-medium-dark);
            }

            .vjs-big-play-button {
              background-color: transparent;
              border-radius: 3.2em;
              width: 3.2em;
              height: 3.2em;
              border-color: var(--base-medium);
              border-width: 8px;
              top: calc(50% - 1em);

              .vjs-icon-placeholder {
                &::before {
                  font-size: 3em;
                  top: 0.19em;
                  left: -0.05em;
                  color: var(--base-medium);
                }
              }
            }

            .vjs-control-bar {
              background-color: var(--base-medium);
              bottom: -3px;
            }
          }
        }

        app-pdf-viewer {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          ngx-extended-pdf-viewer {
            height: 100%;
            width: 100%;
          }
        }
      }

      app-audio-media-player {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        .row {
          width: 100%;

          audio-player {
            width: 100%;

            span {
              color: #fff;
              font-weight: 500;
            }

            mat-card {
              border-radius: 0;
              box-shadow: none !important;
              background: var(--base-medium);

              .mat-mdc-slider .mdc-slider__thumb-knob,
              .mat-mdc-slider .mdc-slider__track--inactive,
              .mat-mdc-slider .mdc-slider__track--active_fill {
                border-color: var(--base-medium-dark-alt);
                background-color: var(--base-medium-dark-alt);
              }

              .mat-mdc-slider .mdc-slider__track--active {
                background-color: var(--base-medium-light);
              }

              button {

                mat-icon,
                span {
                  color: #fff;
                }
              }
            }

            .track-control {
              margin-left: 15px;
              margin-top: 0.12rem;
            }

            .volume-slider {
              max-width: 12vw;
              margin-top: 0.12rem;
            }
          }
        }
      }

      .media-details {
        table {
          td {
            span.semi-bold {
              margin-right: 0.2rem;
            }
          }
        }
      }
    }

    .aia-display {
      .media-container {
        height: 100%;

        .media-preview {
          max-height: none;
          height: 100%;

          iframe {
            background: white;
            border: 1px solid var(--base-dark);
            padding: 15px;
          }
        }
      }
    }
  }

  .management-card {
    padding: 0;
    margin: 0.5rem 0;

    .table-responsive {
      border-radius: 8px;
    }

    .edit-add-dropdown {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;

      .activator {
        padding-top: 0;
        padding-bottom: 0;

        mdb-icon {
          transition: 250ms linear;
        }
      }

      .dropdown-menu {
        right: 0;
        min-width: 16rem;
        margin-top: 0.7rem;

        .dropdown-item {
          &:hover {
            background-color: var(--base-medium-dark);
          }
        }
      }
    }
  }

  .p-element {
    &.delete-confirm {

      color: var(--base-dark);
      font-family: "Noto Sans", sans-serif;

      .p-dialog-header {
        padding: 0;
        width: 100%;

        .modal-header {
          width: 100%;
          padding: 0.72rem 0.6rem 0.7rem;
          border-bottom: 1px solid var(--base-dark);
          background: var(--base-modal-medium);
        }
      }

      .p-dialog-content {
        padding: 1.2rem 1.3rem;
        justify-content: center;
      }

      .p-dialog-footer {

        padding: 0;
        width: 100%;

        .modal-footer {
          padding: 11px 27px;
          border-top: 1px solid var(--base-dark);
          background: var(--base-modal-medium);
        }
      }
    }

    .p-datatable {
      .p-datatable-thead {
        z-index: 2;
      }

      .p-datatable-thead>tr>th {
        background: var(--base-medium-dark);
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        font-family: "Noto Sans", sans-serif;
        padding: 0.6rem 1rem;

        &:first-child {
          border-radius: 8px 0 0 0;
        }
      }
    }
  }

  table.table {

    td,
    th {

      // padding: 16px 1.1rem;
      &.action-column {
        .theme-action-icon-button {
          margin-right: 7px;
        }
      }

      .btn.btn-link {
        padding: 0 0.535rem;
        margin: 0;
      }

      &.type {
        width: 15.4%;

        .chip {
          margin: 0;
          border-radius: 8px;
          background-color: var(--base-gray-dark);
          font-weight: 600;
          color: white;
          cursor: default;

          &.type-3d-object,
          &.type-generic {
            background-color: var(--base-modal-dark);
          }

          &.type-image,
          &.type-accepted {
            background-color: var(--base-medium);
          }

          &.type-video {
            background-color: var(--base-medium-light);
          }

          &.type-audio {
            background-color: var(--base-medium-dark-alt);
          }

          &.type-pdf,
          &.type-invited {
            background-color: var(--base-warning);
          }
        }
      }
    }

    colgroup.client-list {
      col {
        &.col-action-icons {
          width: 70px;
        }

        &.col-thumbnail {
          width: 15%;
        }

        &.col-client-name {
          width: 20%;
        }

        &.col-client-code {
          width: 12%;
        }

        &.col-start-date {
          width: 15%;
        }

        &.col-end-date {
          width: 15%;
        }

        &.col-team-id {
          width: 8%;
        }
      }
    }

    &.client-list-table {

      td,
      th {
        &.action-icons {
          width: 70px;
        }

        &.thumbnail {
          width: 14%;
        }

        &.client-name {
          width: 15%;
        }

        &.client-code {
          width: 8%;
        }

        &.start-date {
          width: 15%;
        }

        &.end-date {
          width: 15%;
        }

        &.team-id {
          width: 8%;
        }
      }
    }

    colgroup.events {
      col {
        &.col-actions {
          width: 10.8%;
        }

        &.col-event_name {
          width: 12.47%;
        }

        &.col-start_at {
          width: 13.47%;
        }

        &.col-end_at {
          width: 13.47%;
        }

        &.col-access {
          width: 16.82%;
        }

        &.col-userCount {
          width: 10%;
        }

        &.col-userGroupCount {
          width: 10.5%;
        }

        &.col-experiences {
          width: 12.47%;
        }
      }
    }

    colgroup.users {
      col {

        &.col-actions {
          width: 9%;
        }

        &.col-first_name {
          width: 11.7%;
        }

        &.col-last_name {
          width: 11.7%;
        }

        &.col-email {
          width: 24%;
        }

        &.col-username {
          width: 14.6%;
        }

        &.col-role_type_id {
          width: 15%;
        }

        &.col-meta {
          width: 15%;
        }
      }

      &.with-persona {
        col {
          &.col-email {
            width: 11%;
          }
        }
      }
    }

    colgroup.user-groups {
      col {
        &.col-actions {
          width: 13%;
        }

        &.col-name {
          width: 29%;
        }

        &.col-number_of_members {
          width: 29%;
        }

        &.col-number_of_events {
          width: 29%;
        }
      }
    }

    thead {
      th {
        padding: 0.6rem;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        border-bottom-width: 0px;

        &.col-actions {
          padding-left: 0;
          padding-right: 0;
        }

        .header-wrapper {
          display: flex;
          align-items: center;
        }

        .sort-icon {
          margin-left: 4px;
          cursor: pointer;
        }

        .md-form {
          min-width: 0;
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .sorting {
        th {
          padding-bottom: 0;
        }
      }

      .filters {
        th {
          padding: 6px 1.2% 8px;

          .md-form {
            &.md-filter-input {
              input {
                padding: 0.2rem 0.7rem 0.2rem 0.6rem;
                position: relative;
              }
            }

            mdb-date-picker {
              width: 100%;
            }
          }
        }
      }

      &.users {
        th {
          &.head-actions {
            width: 9%;
            // padding: 10px 0.6% 12px;

            .download-csv {
              button {
                font-size: 13px;
                padding: 0 0.4rem;
                min-width: 120px;
                max-height: 29px;

                span,
                fa-icon {
                  padding-top: 0.12rem;
                }
              }
            }

          }

          &.head-first_name {
            width: 11.7%;
          }

          &.head-last_name {
            width: 11.7%;
          }

          &.head-email {
            width: 24%;
          }

          &.head-username {
            width: 14.6%;
          }

          &.head-role_type_id {
            width: 15%;
          }

          &.head-meta {
            width: 15%;
          }
        }
      }
    }

    .md-filter-input {
      display: flex;
      align-items: center;
      margin: 0 0 0.4rem;
      position: relative;
      border-radius: 8px;
      padding-right: 0.2em;

      &.disabled {
        opacity: 0.62;
      }

      input {
        padding: 0.2rem 1.6rem 0.2rem 0.3rem;
        border-radius: 8px;
        background: white;
        margin: 0;
        font-size: 14px !important;
      }

      .ng-fa-icon {
        color: var(--base-medium-dark);
        font-size: 17px;
        position: absolute;
        right: 1.7rem;
        top: 0.15rem;
        font-size: 16px;
      }

      &.md-filter-select,
      &.md-filter-access,
      &.md-filter-end_at,
      &.md-filter-start_at {
        .ng-fa-icon {
          right: 1.6rem;
          top: 0.21rem;
          font-size: 16px;
        }
      }

      &.md-filter-number {
        input {
          padding-right: 1.2rem;
        }
      }

      mdb-date-picker {
        .datepicker-icon {
          color: var(--base-dark);
          font-size: 17px;
          top: -3px;
          right: 0;
        }
      }

      span {
        font-size: 14px;
      }

      mdb-select-2 {
        flex: 1;
        background: #fff;
        border-radius: 8px;

        .mdb-select-wrapper {
          height: 28px;

          .mdb-select-value {
            padding: 0 1.2rem 0 0.2rem;
            margin: 0.1rem 2px;
            border-bottom: none;
            box-shadow: none;

            .mdb-select-value-label span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    tbody {
      td {
        padding: 0.8rem 1.2%;
        font-size: 14px;
        vertical-align: middle;
        line-height: 1.6;

        &.col-actions {
          padding-left: 0;
          padding-right: 0;
        }

        &.action-icons,
        .action-icons {
          padding-left: 0.4%;
          padding-right: 0.4%;
          color: var(--base-dark);
          text-align: center;

          .empty-query {
            position: absolute;
            bottom: -2rem;
          }

          .btn:disabled {
            opacity: 0.42;
          }
        }

        span {
          &.date {
            font-weight: 600;
            color: var(--base-medium-dark-alt);

            &.different-day {
              color: var(--base-medium-light);
            }
          }

          &.time {
            color: var(--base-medium-dark-alt);
          }
        }

        &.access-code {
          padding-top: 0;
          padding-bottom: 0;

          .input-group {
            margin-top: 4px;

            input {
              border-radius: 8px 0 0 8px;
              height: 32px;
              padding: 0.375rem 0.4rem;

              &.code-hidden {
                letter-spacing: 6px;
                font-size: 35px;
              }
            }

            .input-group-text {
              padding: 0.2rem 0;
              font-size: 1rem;

              fa-icon {
                width: 27px;
              }
            }
          }

          .input-end {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }

    &.with-persona {
      thead {
        th {
          font-size: 13px;
        }
      }
    }
  }

  // mdb-select-dropdown {
  //   .dropdown-content {
  //     li {
  //       > a,
  //       > span {
  //         color: var(--base-medium);
  //       }
  //     }
  //   }
  // }
  .form-check-input[type="checkbox"]+label:before {
    border-color: #000;
  }

  .form-check-input[type="checkbox"]:checked+label:before {
    border-color: transparent var(--base-medium-dark) var(--base-medium-dark) transparent;
  }

  .md-form label {
    color: #000;

    &.active-label {
      transform: translateY(-14px) scale(0.8);
    }
  }

  .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    // padding: 72px 15px 1px;
    min-height: 100vh;
    position: relative;
    padding-left: 0;
    padding-right: 0;

    .footer-wrapper {
      padding-left: 20px;
      padding-bottom: 10px;
      position: absolute;
      bottom: 0;
    }

    app-footer {

      // position: absolute;
      // bottom: 0;
      span {
        font-size: 11px;
      }
    }
  }

  .container-fluid.top-container {
    padding-left: 276px;
  }

  @media only screen and (max-width: 1440px) {
    .container-fluid.top-container {
      padding-left: 0px;
    }
  }

  .wrapper-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .table-remove {
    button {
      background-color: var(--base-status-error);
      border: var(--base-dark) 2px solid;
      color: var(--base-dark);
    }
  }

  .app-container {
    height: 100%;
    min-height: 100vh;
    margin-right: 36px;

    .loading-screen {
      height: 63vh;
      align-content: center;
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;

    &.disabled {
      opacity: 0.5;
    }
  }

  .media-management-card {
    margin-top: 0.7em;
  }

  .media-table-card {
    margin-top: 1.25em;

    .table {
      colgroup {
        col {
          &.col-action-icons {
            width: 12%;
          }

          &.col-thumb {
            width: 140px;
          }

          &.col-size {
            width: 11%;
          }

          &.col-type {
            width: 15.4%;
          }

          &.col-owner_username {
            width: 16.2%;
          }

          &.col-created {
            width: 15.4%;
          }
        }

      }

      th {
        &.owner_username {

          .ap-autocomplete {
            button.mdb-autocomplete-clear {
              top: 1px;
              right: -1rem;
            }
          }

          .completer-dropdown {
            color: black;
            font-weight: 400;
          }
        }
      }

      tr {

        td,
        th {

          padding: 0.54rem 1.2%;

          &.text-content {
            padding: 0.5rem 2.2%
          }

          &.action-icons {
            width: 12%;

            .no-media {
              position: absolute;
            }

            .action-icons {
              .selected-icons>a {

                padding: 0;
                margin: 0.2rem 0 0 0.2rem;

                fa-icon {
                  font-size: 25px;
                  color: var(--base-medium);
                }

                &.plus-square {
                  fa-icon {
                    color: var(--base-medium-dark-alt);
                  }
                }
              }
            }
          }

          &.thumbnail-container {
            width: 10rem;
            padding: 0.54rem 20px;

            .img-thumbnail {
              min-width: 120px;
              height: 120px;
              overflow: hidden;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 100%;
              cursor: pointer;
              border-radius: 8px;
              position: relative;
            }

            .icon-holder {
              position: absolute;
              background: white;
              border-radius: 8px;
              bottom: 0.3rem;
              right: 0.3rem;
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 2px;

              fa-icon {
                font-size: 16px;
              }

              img {
                max-height: 16px;
                width: auto;
              }

              &.is-360 {
                bottom: auto;
                top: 0.3rem;
                padding-top: 0;
              }
            }
          }

          &.name {
            .ellipsis-wrapper {
              width: 14vw;
              max-width: 100%;
            }
          }

          &.size {
            width: 11%;
          }

          &.type {
            width: 15.4%;
          }

          &.owner_username {
            width: 16.2%;
          }

          &.created {
            width: 15.4%;
          }
        }
      }
    }
  }

  .modal {
    .model-preview {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1/1;
      background: var(--base-medium-light-alt);

      app-wc-model-viewer {
        width: 100%;
        height: 100%;
        position: relative;

        .thumbnail-button {
          position: absolute;
          right: 1rem;
          bottom: 1rem;
        }

        .model-wrapper {
          width: 100%;
          height: 100%;

          model-viewer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .media-details {
      .table {
        tbody {
          tr {
            td {
              .content-plus {
                display: flex;
                align-items: center;
                justify-content: left;
              }
            }
          }
        }
      }
    }

    .media-uploads {
      .files {
        padding-right: 1.3em;
        margin-top: 0.65rem;

        .style-table-cell {
          padding: 0.42rem 0.6rem;

          &.actions {
            width: 9%;
            text-align: center;
            vertical-align: middle;

            .btn-flat {
              padding: 0;
              margin: 0.2rem 0 0 0.2rem;

              fa-icon {
                font-size: 25px;
                color: var(--base-medium);
              }

              &.remove-invalid {
                margin-left: 0.8rem;

                fa-icon {
                  color: var(--base-error);
                }
              }
            }
          }

          &.preview {
            width: 15%;
            position: relative;

            .thumbnail-container {
              width: 120px;
              position: relative;
              height: auto;
              padding: 0.54rem 0;

              .img-thumbnail {
                min-width: 120px;
                height: 120px;
                overflow: hidden;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                cursor: pointer;
                border-radius: 8px;
                position: relative;
              }
            }
          }

          &.filename {
            width: 15%;
            vertical-align: middle;
            font-size: 14px;
            position: relative;

            .ellipsis-wrapper {
              width: 11.4vw;
            }

            .extras {
              position: absolute;
              bottom: 1.6rem;

              .checkbox {
                margin: 0;
                height: 1.6rem;

                label {
                  margin: 0;
                  font-size: 14px;
                  padding-left: 1.6rem;

                  &:before {
                    top: 1px;
                  }
                }
              }
            }
          }

          &.size {
            width: 10%;
            vertical-align: middle;
            font-size: 14px;
          }

          &.progress-wrapper {
            vertical-align: middle;

            .pending {
              color: var(--base-medium-alt);
              font-size: 14px;
              display: flex;
              align-items: center;

              fa-icon {
                font-size: 25px;
                margin-right: 1rem;
              }
            }

            .error {
              color: var(--base-error);
              font-size: 14px;
              display: flex;
              align-items: center;

              fa-icon {
                font-size: 25px;
                margin-right: 1rem;
              }
            }

            .success {
              color: var(--base-zebra-on);
              font-size: 14px;
              display: flex;
              align-items: center;

              fa-icon {
                font-size: 25px;
                margin-right: 1rem;
              }
            }

            .progress {
              height: 1.3rem;
              border-radius: 10px;
              background-color: var(--base-medium);
              padding: 2px;
              display: none;

              .progress-bar {
                background-color: var(--base-modal-medium-light);
                border-radius: 10px;
              }
            }
          }
        }

        .file-row {
          &.dz-processing {
            .progress {
              display: flex;
            }

            .pending {
              display: none;
            }
          }
        }

        .remove-invalid {
          display: none;
        }
      }

      .file-row {
        &:nth-of-type(odd) {
          background: var(--base-modal-light);
        }

        &.invalid {
          .remove-standard {
            fa-icon {
              color: var(--base-error);
            }
          }

          .remove-invalid {
            display: block;
          }

          .progress {
            display: none;
          }
        }
      }
    }
  }
}

//specific page targeted styles
body {
  &.inventory-session-results {
    .tooltip {
      margin-top: -5.6em;

      .tooltip-arrow {
        &::before {
          border-top-color: var(--base-modal-medium);
        }
      }

      .tooltip-inner {
        max-width: 34em;
        padding: 0.8rem 0.6rem;
        background-color: var(--base-modal-medium);
        color: var(--base-dark);
      }
    }
  }

  &.all-media {
    .upload-embed {
      display: none;
    }
  }

  &.login {
    height: auto;

    .container-fluid.top-container {
      padding: 0;
      min-height: 0;
    }

    .app-container {
      margin: 0;
      height: auto;
      min-height: 0;
    }
  }
}

// newer styles
.heading-content {
  margin: 1.5em 0;
}

.content-card {
  @extend .heading-content;
  padding-bottom: 1.5em;
}

.admin-tab-styles {
  .classic-tabs {
    // background-color: red;
    padding-top: 1em;

    .active.nav-item {
      border-bottom: 2px solid var(--base-dark);
    }

    .nav-item {
      a {
        color: var(--base-dark);
      }
    }

    .disabled.nav-item {
      a {
        color: var(--base-medium-light);
      }
    }
  }

  .updated-tabs {
    .nav-item {
      margin-right: 1em;

      a {
        color: var(--base-dark);
        font-weight: 600;
        padding: 0.5em 0 0;
        font-size: 16px;
      }

      &.active {
        border-bottom: 5px solid var(--base-dark);
      }
    }
  }
}

.tab-content.remove-card {
  padding: 0;
  box-shadow: none;
}

.list-btns-sm {
  .btn.btn-sm {
    padding: 0.5rem 0.5rem;
  }
}

.loading-text {
  margin-top: 1.5rem;
  margin-left: 15px;
}

.loading-text-alt {
  margin-top: 2.5rem;
}

.loading-msg:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0px;
}

// // Bootstrap 4 breakpoints & gutter
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
// ) !default;

// $grid-gutter-width: 0 !default;

// // number of cards per line for each breakpoint
// $cards-per-line: (
//   xs: 1,
//   sm: 2,
//   md: 3,
//   lg: 4,
//   xl: 4,
// );

// @each $name, $breakpoint in $grid-breakpoints {
//   @media (min-width: $breakpoint) {
//     .card-group .display-card {
//       flex: 0 0
//         calc(#{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
// min-width: calc(
//   #{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width}
// );
//     }
//   }
// }

// display: grid;
// grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));

// search form input {
.md-form input[type="search"]:focus:not([readonly]),
.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 var(--base-dark);
  border-bottom: 1px solid var(--base-dark);
}

.md-form input[type="search"]:focus:not([readonly])+label,
.md-form input[type="password"]:focus:not([readonly])+label,
.md-form input[type="number"]:focus:not([readonly])+label,
.md-form input[type="text"]:focus:not([readonly])+label {
  color: var(--base-modal-dark);
}

// radio btns
.form-check-input[type="radio"]:checked+label:after {
  background-color: var(--base-modal-dark);
  border-color: transparent;
  top: 1px;
  width: 15px;
  height: 15px;
  left: 2px;
  transform: scale(1.1);
}

.form-check-input[type="radio"]+label:before {
  width: 18px;
  height: 18px;
}

.form-check-input[type="radio"]:disabled:checked+label:before,
.form-check-input[type="radio"]:checked+label:before,
.form-check-input[type="radio"]:not(:checked)+label:before {
  background-color: transparent;
  border-color: var(--base-dark);
  z-index: 1;
}

.md-form {
  &.ap-autocomplete {
    min-width: 150px;

    input {
      &::placeholder {
        color: #292b2c;
      }
    }

    button.mdb-autocomplete-clear {
      top: 16px;
    }
  }
}

.form-check-input[type="checkbox"]:checked+label:before {
  border-color: transparent var(--base-dark) var(--base-dark) transparent !important;
}

// modal styles
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// backdrop in modals
// .background-replacement {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1041;
//   width: 100vw;
//   height: 100vh;
//   opacity: 0;
// }
// .class-wrapper {
//   z-index: 1042;
//   &.confirm-on {
//     opacity: 0.5;
//   }
// }

.background-replacement {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1041;
  width: 100vw;
  height: 100vh;
  opacity: 0;
}

.class-wrapper {
  z-index: 1042;

  &.confirm-on {
    opacity: 0.5;
  }
}

.confirm-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

.cdk-overlay-container {
  z-index: 1051 !important;
}

.meta-experience {
  max-width: 900px !important;
}

.addEditUser {
  max-width: 500px !important;
}

.view-experience-modal {
  max-width: 1000px !important;
}

.adjust {
  .modal-content {
    width: 32vw !important;
    margin: 0 auto;
    height: 320px !important;
  }
}

.add-student-container {
  .modal-dialog {
    max-width: 60vw;
  }
}

.sec-btn,
.btn.sec-btn {
  padding: 0.5rem 1rem;
  background-position: 0 0;
  background-size: 1546px auto;
  position: absolute;
  height: 1192px;
  top: -10px;
  left: -6px;
  right: 0;
  opacity: 0.5;
  width: 1546px;
  z-index: -1;
}

/**override mdb**/
body {
  .mx-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

/**for app containers in modals**/
.modal {

  app-media-manager,
  app-media-manager-table-view {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.card {
  &.authoring-card {

    border-radius: 8px 8px 0 0;

    .p-datatable-wrapper {
      max-height: 89vh;
    }

    .authoring-table {

      tr {

        td {

          .action-order {
            width: 50px;
            margin-left: 8px;
          }

          &.action-icons {
            padding: 1rem 1rem;
          }

          .btn-group {
            display: flex;
            justify-content: center;
            align-items: center;

            .pi-bars {
              margin-right: 0.8rem;
            }
          }

          span,
          div,
          label {
            font-size: 14px;
          }

          z-index: 1;

          &.action-parameters {
            .parameter-wrapper {

              .parameter-name {
                display: flex;
                align-items: center;

                .parameter-label {
                  display: block;
                  white-space: nowrap;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .updating-notification,
                .success-notification {
                  margin-left: 0.5rem;
                }

                .updating-notification {
                  .spinner-border {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }

              min-width: 180px;
              max-width: 180px;

              input[type=text],
              textarea {
                min-width: 180px;
              }

              input[type=number] {
                min-width: 80px;
              }
            }
          }
        }

        &.action-selected {

          td,
          span,
          label {
            color: #fff;
          }

          .pre {
            white-space: pre-wrap;
          }

          .default {
            font-style: italic;
            color: var(--base-gray-medium);

            span,
            div,
            label {
              font-style: italic;
              color: var(--base-gray-medium);
            }
          }

          .editing {

            td,
            span,
            label,
            input,
            .p-inputwrapper {
              color: #000;

            }

            .inputgroup {
              display: flex;
            }
          }

          td {
            &.required {
              background-color: var(--base-status-error);

              .parameter-name,
              .default {
                color: var(--base-dark);
              }
            }
          }
        }

        &.last-row {
          td {
            padding-top: 2rem;
          }
        }

      }

      // min-height: 90vh;
      .table-actions {
        min-height: 50px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

      }
    }

    .table-actions {
      position: absolute;
      bottom: 1rem;
      z-index: 1;
    }
  }
}

html,
body {
  height: 100%;
}

.readquest-analytics-highlevel {
  height: 88vh;
}

.readquest-analytics-chart {
  height: 88vh;
}

.readquest-analytics-skill-attempts {
  min-height: 20vh;
  max-height: 100vh;
}

.skill-card {
  .no-actions-message {
    margin-top: 3.8rem;

    p {
      color: white;
    }
  }
}

.skills-table-card {
  .table {
    .skill-media-pdf {
      width: 12vw;
    }

    .lists-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 10vw;

      button {
        cursor: pointer;
      }
    }
  }
}

.manage-phonemes-container {
  .edit-form {
    input {
      width: 8rem;
    }
  }
}

.interactive-analytics-card {

  .back-button {
    width: 4.2rem;
    text-align: left;
    line-height: 1;
  }

  .aia-model-selection{
    width: 8.6rem;
  }

  h3 {
    font-size: 18px;
    margin: 1rem 0;
  }

  .metrics-controls {

    margin: 0.7rem 0 1rem;

    label {
      display: none;
    }

    .md-form {
      .form-control {
        margin-bottom: 0;
        padding: 0.45rem 0 0.4rem;
      }
    }

    // todo: style for the active states and selection in the date pickers and dropdown
    .start-picker {
      margin-left: 15px;
    }

    .start-picker,
    .end-picker {
      .picker__box .picker__table .picker__day--selected {
        background-color: var(--base-medium-dark);
      }

      .picker__box .picker__table td.picker__day div.picker__day.picker__day--today {
        color: var(--base-dark);
      }
    }

    .start-picker,
    .end-picker,
    .denomination {
      margin: 0 3.5% 0 0;
      width: 23.5%;

      mdb-select>div>div.single.focused {
        box-shadow: 0 1px 0 0 var(--base-dark);
        border-bottom: 1px solid var(--base-dark);
      }

      mdb-select+label.focused {
        color: var(--base-dark);
      }

      .mdb-select-toggle.focused {
        color: var(--base-dark);
      }

      // dropdown soon to be migrated to the main style file
      .dropdown-content {
        top: auto !important;

        li>span {
          color: #757575;
        }

        &>.options ul .selected {
          background-color: var(--base-medium-dark);

          span {
            color: white;
          }
        }

        &>.options ul .heavy-rain-gradient {
          background-image: linear-gradient(to top,
              var(--base-dark) 0%,
              var(--base-medium-dark) 100%);
        }
      }
    }

    .update-btn {
      width: 19%;
    }
  }

  .ag-cell {
    align-items: center;
    display: flex;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    .ag-cell-value {
      line-height: 1.5;
    }
  }

  .aggregate-container {
    table {
      margin-top: 1.4rem 0 0;

      tr {
        td {
          &.aggregate-value {
            padding-left: 0.4rem;
          }

          &.aggregate-label {
            padding-right: 0.4rem;
          }
        }
      }
    }
  }

  &.aia-analytics {
    margin-top: 12px;
  }
}

.interactive-meetings-card {
  .meeting-header {
    margin-top: 2rem;
  }

  .meeting-search,
  .meeting-table {
    margin-right: 15px;
    margin-left: 15px;
  }

  .meeting-table {
    flex: auto;

    .meeting-nav-table {
      table-layout: fixed;

      thead {

        th:nth-child(2),
        th:nth-child(3) {
          text-align: center;
        }
      }
    }

    tr {
      cursor: pointer;
    }

    .tooltip-inner {
      max-width: 500px !important;
    }

    .session-name-container {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

#stylingGuide {
  display: none;
  background: url("assets/img/styling.jpg") no-repeat;
  background-position: 0 0;
  background-size: 1546px auto;
  position: absolute;
  height: 1192px;
  top: -7px;
  left: 10px;
  right: 0px;
  opacity: 0.5;
  width: 1546px;
  z-index: 2000;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ellipsis {

  0%,
  20% {
    content: ".";
  }

  40% {
    content: "..";
  }

  60% {
    content: "...";
  }

  80%,
  100% {
    content: "";
  }
}